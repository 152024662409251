import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import { GUIDES_LINKS } from "components/docs/navigation/links";
import NavigationScrollTracker from "components/docs/navigation/NavigationScrollTracker";
import NavigationScrollTrackerWithAnchor from "components/docs/navigation/NavigationScrollTrackerWithAnchor";
import SmallWidthSection from "components/docs/sections/SmallWidthSection";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { StaticFancyPageContainerWithTOC } from "components/docs/shared-components/StaticFancyPageContainer";
import { Tile } from "components/docs/tile/Tile";
import { PageProps } from "gatsby";
import React from "react";
import styled from "styled-components";

/**
 * Small width section with smaller padding above
 */
const ShortSection = styled(SmallWidthSection).attrs({ className: "mb-9" })`
  margin-top: 0px;
`;

/**
 * Pads the tile differently than default
 */
const StyledTile = styled(Tile)`
  &&& {
    margin-bottom: 24px;

    .deprecatedh4,
    h5 {
      margin-bottom: 8px;
    }
    .deprecatedh5,
    h6 {
      margin-bottom: 0;
    }
  }
`;

/**
 * Creates an Overview for Merge Writes guide
 */
const WritesOverview = ({
  location,
}: PageProps<unknown, unknown, Record<string, unknown> | null>) => (
  <StaticFancyPageContainerWithTOC
    tableOfContents={[
      {
        text: "Your Journey to Writes",
        linkItems: [{ text: "Basic Writes" }, { text: "Advanced Writes" }],
      },
    ]}
  >
    <DocsHelmet
      title="Merge Writes: Overview"
      description="Learn about the components of Merge Writes."
    />
    <SmallWidthSection>
      <NavigationScrollTracker>
        <HeaderBar
          title="Overview"
          subtitle="These guides cover how to write data to third-party platforms"
        />
      </NavigationScrollTracker>
    </SmallWidthSection>

    <ShortSection>
      <NavigationScrollTrackerWithAnchor headingLevel="h3" title="Your Journey to Writes">
        <p>
          For convenience, we’ve broken up the journey of learning how to write data to third-party
          platforms into Basic and Advanced topics.
        </p>
      </NavigationScrollTrackerWithAnchor>
      <NavigationScrollTrackerWithAnchor headingLevel="h4" title="Basic Writes">
        <p className="mb-9">
          In our Basic topics, we introduce universal concepts that allow you to execute writes for{" "}
          <strong>most</strong> — but not all — third-party platforms and Linked Accounts.
        </p>
        <StyledTile {...GUIDES_LINKS.WRITES_INTRO} />
        <StyledTile {...GUIDES_LINKS.WRITES_RELATED_NESTED} />
        <aside className="mt-6">
          <p>Writes are unified across all of our third-party platforms.</p>
          <p>
            However, some platforms require an additional piece of Merge’s advanced functionality —
            explored in <strong>Advanced Writes</strong> below — to support them.
          </p>
        </aside>
      </NavigationScrollTrackerWithAnchor>

      <NavigationScrollTrackerWithAnchor
        headingLevel="h4"
        title="Advanced Writes"
        className="mt-16"
      >
        <p className="mb-9">
          In our Advanced topics, we introduce programmatic concepts that make heavy use of an
          endpoint we call <code>/meta</code> to handle writes for <strong>all</strong> third-party
          platforms and Linked Accounts.
        </p>
        <StyledTile {...GUIDES_LINKS.WRITES_PROGRAMMATIC_INTRO} />
        <StyledTile {...GUIDES_LINKS.WRITES_PROGRAMMATIC_NESTED} />
        <StyledTile {...GUIDES_LINKS.WRITES_PROGRAMMATIC_TEMPLATES_CONDITIONAL} />
      </NavigationScrollTrackerWithAnchor>
    </ShortSection>

    <SmallWidthSection className="mb-9 mt-9">
      <CrossGuideLinks location={location} usesQuaternaryLinks />
    </SmallWidthSection>
  </StaticFancyPageContainerWithTOC>
);

export default WritesOverview;
